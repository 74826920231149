import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
  inject,
} from '@angular/core';
import { navItems } from './sidebar-data';
import { Router } from '@angular/router';
import { NavService } from '../../../../services/nav.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { AppHorizontalNavItemComponent } from './nav-item/nav-item.component';
import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { navItemsConsultory, navItemsConsultoryWithExternalAndInternal, navItemsConsultoryWithInternalWithOutExternal, navItemsDoctorConsultoryWithOutCurrentConsulting } from './siderbar-data-doctor';
import { ChageItemsMenuService } from '../../shared/services/chage-items-menu.service';
import { Subscription } from 'rxjs';
import { navItemsFarmacy } from './sidebar-data-farmacy';
import { navItemsAdministrativeDirection } from './siderbar-data-administrative-direction';

@Component({
  selector: 'app-horizontal-sidebar',
  standalone: true,
  imports: [AppHorizontalNavItemComponent, NgIf, NgForOf, CommonModule],
  templateUrl: './sidebar.component.html',
})
export class AppHorizontalSidebarComponent implements OnInit {
  navItems = navItems;
  parentActive = '';
  typeRole:string | null;
  patiendId:string | null;
  internalConsultationId:string | null;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;  

  public subscription: Subscription;

  constructor(
    public navService: NavService,
    public router: Router,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    public chageItemsMenuService: ChageItemsMenuService

  ) {    
    this.mobileQuery = media.matchMedia('(min-width: 1100px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe(
      () => (this.parentActive = this.router.url.split('/')[1])
    );

    this.subscription = this.chageItemsMenuService.getEvent().subscribe(()=> {                  
      this.getTypeRole();
    });

    
  }

  ngOnInit(): void {
    this.getTypeRole();
  }

  getTypeRole(): any[] {                    
    this.typeRole  = localStorage.getItem('role');    
    this.patiendId = localStorage.getItem('patientId');  
    this.internalConsultationId = localStorage.getItem('internalConsultationId');                 

    if(this.typeRole == 'Doctor'){      
      if(this.patiendId !== null && this.internalConsultationId !== null  ){
        this.navItems = navItemsConsultoryWithExternalAndInternal;        
      }

      if(this.patiendId == null && this.internalConsultationId == null  ){
        this.navItems = navItemsDoctorConsultoryWithOutCurrentConsulting;                
      }

      if(this.patiendId !== null && this.internalConsultationId == null  ){
        this.navItems = navItemsConsultory;                        
      }

      if(this.patiendId == null && this.internalConsultationId !== null  ){
        this.navItems =  navItemsConsultoryWithInternalWithOutExternal;        
      }     
    }      

    if (this.typeRole == 'Administrator') {
      this.navItems = navItemsAdministrativeDirection;
      return this.navItems;
    }        
    
    if (this.typeRole == 'PharmacyManager') {
      this.navItems = navItemsFarmacy;
      return navItemsFarmacy;
    }   
    
    return [];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  

  
}
