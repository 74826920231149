import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';

export const navItemsFarmacy: NavItem[] = [
  {
    navCap: 'Desk',
  },
  // {
  //   displayName: 'Pedidos',
  //   iconName: 'home',
  //   route: '/farmacia/pedidos-de-hospital', 
  //   children: [
  //     {
  //       displayName: 'Pedidos del hospital',
  //       iconName: 'point',
  //       route: 'farmacia/pedidos-de-hospital',
  //     },
  //     {
  //       displayName: 'Pedidos de consultorio',
  //       iconName: 'point',
  //       route: '/farmacia/pedidos-de-consultorio',
  //     },          
  //   ],   
  // },      
  {
    displayName: 'Ventas',
    iconName: 'layout',
    route: '/farmacia/ventas',    
  }, 
  {
    displayName: 'Ventas totales',
    iconName: 'layout',
    route: '/farmacia/ventas-totales',    
  }, 
  {
    displayName: 'Surtir farmacia',
    iconName: 'file-description',
    route: '/farmacia/surtir-farmacia',    
  },   
  {
    displayName: 'Inventario',
    iconName: 'file-description',
    route: '/farmacia/inventario',    
  },   
  
];
